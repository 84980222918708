import React from 'react';
import Helmet from 'react-helmet';
import loadable from '@loadable/component';
import { renderContent } from '@quantore/gatsby-theme-quantore/src/utils';
import Container from '@quantore/gatsby-theme-quantore/src/components/Container';
import Breadcrumb from '@quantore/gatsby-theme-quantore/src/components/Breadcrumb/Breadcrumb';
import PageLoader from '@quantore/gatsby-theme-quantore/src/components/Loader/PageLoader';
import { graphql } from 'gatsby';
import FadeIn from 'react-fade-in';

import {
  Main,
  PageContainer,
  ContentContainer,
  PageTitle,
  PreTitle,
  Date,
  Content,
  Image,
  Caption,
} from './styles';

const ImageMask = loadable(
  () =>
    import(
      '@quantore/gatsby-theme-quantore/src/components/ImageMask/ImageMask'
    ),
  {
    fallback: <PageLoader />,
    cacheKey: () => 'ImageMask',
  }
);

const ArticleTeaser = loadable(
  () =>
    import(
      '@quantore/gatsby-theme-quantore/src/components/ArticleTeaser/ArticleTeaser'
    ),
  {
    fallback: <PageLoader />,
    cacheKey: () => 'ArticleTeaser',
  }
);

const ComponentList = loadable(
  () =>
    import(
      '@quantore/gatsby-theme-quantore/src/components/ComponentList/ComponentList'
    ),
  {
    fallback: <PageLoader />,
    cacheKey: () => 'ComponentList',
  }
);

const ArticlePage = ({
  pageContext,
  location,
  data: { contentfulArticlePage },
}) => {
  const {
    breadcrumbLabel,
    category,
    breadcrumbs,
    date,
    title,
    preTitle,
    richDescription,
    image,
    components,
    relatedArticles,
    createdAt,
    metaTagTitle,
    metaTagDescription,
    metaTagRobots,
  } = contentfulArticlePage;

  return (
    <>
      <Helmet>
        <title>{metaTagTitle ? metaTagTitle : title}</title>
        <link rel="canonical" href={location.href} />
        <meta name="title" content={metaTagTitle ? metaTagTitle : title} />
        <meta
          name="robots"
          content={metaTagRobots ? metaTagRobots : 'index, follow'}
        />
        {metaTagDescription && (
          <meta name="description" content={metaTagDescription} />
        )}

        <meta property="og:url" content={location.href} />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content={metaTagTitle ? metaTagTitle : title}
        />
        {metaTagDescription && (
          <meta property="og:description" content={metaTagDescription} />
        )}
        {image && image.file && image.file.url && (
          <meta property="og:image" content={image.file.url} />
        )}

        <meta property="twitter:url" content={location.href} />
        <meta
          property="twitter:title"
          content={metaTagTitle ? metaTagTitle : title}
        />
        <meta property="twitter:card" content="summary_large_image" />
        {metaTagDescription && (
          <meta property="twitter:description" content={metaTagDescription} />
        )}
        {image && image.file && image.file.url && (
          <meta property="twitter:image" content={image.file.url} />
        )}
      </Helmet>

      <Breadcrumb
        breadcrumb={pageContext.breadcrumb}
        label={breadcrumbLabel}
        showBreadcrumbs={breadcrumbs !== false}
      />

      <ContentContainer size="s">
        <FadeIn>
          {(preTitle || category) && (
            <PreTitle>
              {preTitle ? preTitle : category}

              {(date || category === 'Nieuws') && (
                <Date>{date ? date : createdAt}</Date>
              )}
            </PreTitle>
          )}

          <PageTitle>{title}</PageTitle>

          {richDescription && richDescription.raw && (
            <Content>{renderContent(richDescription)}</Content>
          )}
        </FadeIn>
      </ContentContainer>

      {image && image.file && image.file.url && (
        <Container size="l">
          <FadeIn>
            <Image>
              <ImageMask
                image={image}
                gradient={image.description ? 'top' : null}
              />

              {image.description && <Caption>{image.description}</Caption>}
            </Image>
          </FadeIn>
        </Container>
      )}

      <Main>
        <PageContainer size="s">
          {components && <ComponentList components={components} />}
        </PageContainer>
      </Main>

      {relatedArticles && (
        <aside>
          <ArticleTeaser
            list={relatedArticles.list}
            title={relatedArticles.title}
          />
        </aside>
      )}
    </>
  );
};

export default ArticlePage;

export const query = graphql`
  query ArticlePageQuery($id: String!) {
    contentfulArticlePage(id: { eq: $id }) {
      ...ArticlePageFragment
    }
  }
`;
